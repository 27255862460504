
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'DesktopSortView',
  props: {
    totalAccessories: {
      type: Number,
      default: 0,
    },
    activeSort: {
      type: String,
      default: '',
    },
    menuOpen: {
      type: Boolean,
      default: false,
    },
    sorts: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(_, { emit }) {
    function toggleMenu() {
      emit('toggleMenu');
    }
    function updateSort(val: string) {
      emit('updateSort', val);
    }
    return { toggleMenu, updateSort };
  },
});
